import {
  Currency,
  TradeType,
  Percent,
  ONE_HUNDRED_PERCENT,
  Price,
  Trade,
  Fraction,
  CurrencyAmount,
} from '@pancakeswap/sdk'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { formatPrice } from '@pancakeswap/utils/formatFractions'

import { BIPS_BASE, INPUT_FRACTION_AFTER_FEE } from 'config/constants/exchange'
import { Field } from 'state/swap/actions'
import { basisPointsToPercent } from 'utils/exchange'
import { LegacyTrade } from '@pancakeswap/smart-router/legacy-router'
import { TradeWithStableSwap } from '@pancakeswap/smart-router/legacy-router/types'

export type SlippageAdjustedAmounts = {
  [field in Field]?: CurrencyAmount<Currency>
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
export function computeSlippageAdjustedAmounts(
  trade: Trade<Currency, Currency, TradeType> | TradeWithStableSwap<Currency, Currency, TradeType> | undefined | null,
  allowedSlippage: number,
): SlippageAdjustedAmounts {
  const pct = basisPointsToPercent(allowedSlippage)

  return {
    // @ts-ignore
    [Field.INPUT]: trade && LegacyTrade.maximumAmountIn(trade, pct),
    // @ts-ignore
    [Field.OUTPUT]: trade && LegacyTrade.minimumAmountOut(trade, pct),
  }
}

// computes price breakdown for the trade
export function computeTradePriceBreakdown(trade?: Trade<Currency, Currency, TradeType> | null): {
  priceImpactWithoutFee?: Percent | null
  lpFeeAmount?: CurrencyAmount<Currency> | undefined | null
} {
  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce(
          (currentFee: Fraction) => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT,
        ),
      )

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade?.priceImpact?.subtract(realizedLPFee) : undefined

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
    : undefined

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    CurrencyAmount.fromRawAmount(
      trade.inputAmount.currency,
      realizedLPFee.multiply(trade.inputAmount.quotient).quotient,
    )

  return { priceImpactWithoutFee: priceImpactWithoutFeePercent, lpFeeAmount: realizedLPFeeAmount }
}

export function formatExecutionPrice(
  executionPrice?: Price<Currency, Currency>,
  inputAmount?: CurrencyAmount<Currency>,
  outputAmount?: CurrencyAmount<Currency>,
  inverted?: boolean,
): string {
  if (!executionPrice || !inputAmount || !outputAmount) {
    return ''
  }
  return inverted
    ? `${formatPrice(executionPrice.invert(), 6)} ${inputAmount.currency.symbol} / ${outputAmount.currency.symbol}`
    : `${formatPrice(executionPrice, 6)} ${outputAmount.currency.symbol} / ${inputAmount.currency.symbol}`
}

export function v3FeeToPercent(fee: FeeAmount): Percent {
  return new Percent(fee, BIPS_BASE * 100n)
}
